<template>
    <component :is="showTooltip ? 'VTooltip' : 'div'">
        <img
            v-if="imageUrl"
            alt="avatar"
            class="rounded-full ring-2 ring-gray"
            :class="[
                {'size-8': size === 'sm', 'size-10': size === 'md'},
                isAccent ? 'bg-accent' : 'bg-primary',
            ]"
            :src="imageUrl"
        />
        <div
            v-else
            class="flex items-center justify-center rounded-full"
            :class="[
                {'size-8': size === 'sm', 'size-10': size === 'md'},
                isAccent ? 'bg-accent' : 'bg-primary',
            ]"
        >
            <span
                v-if="initials"
                class="select-none font-bold"
                :class="[initialsSize, isAccent ? 'text-black' : 'text-white']"
                translate="no"
                v-text="initials"
            />
            <svg v-else class="h-5" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                    d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 32c52.94 0 96 43.06 96 96c0 52.93-43.06 96-96 96S128 180.9 128 128C128 75.06 171.1 32 224 32zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM413.3 480H34.66C33.2 480 32 478.8 32 477.3C32 399.4 95.4 336 173.3 336h101.3C352.6 336 416 399.4 416 477.3C416 478.8 414.8 480 413.3 480z"
                    fill="var(--white)"
                />
            </svg>
        </div>

        <template v-if="showTooltip" #popper>
            <span v-text="fullName" />
        </template>
    </component>
</template>
<script>
export default {
    props: {
        initials: {
            type: String,
            default: null,
        },
        fullName: {
            type: String,
            default: null,
        },
        imageUrl: {
            type: String,
            default: null,
        },
        showTooltip: Boolean,
        size: {
            type: String,
            default: 'sm',
        },
        initialsSize: {
            type: String,
            default: 'text-sm',
        },
        isAccent: Boolean,
    },
};
</script>
